/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesMilk: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Milk (Treasure) Guide & Review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_milk.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Milk (Treasure) Guide & Review</h1>
          <h2>
            A guide & review for Milk (Treasure) in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>27/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Milk (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="milk-treasure"
                  enablePopover
                />
              </div>
              <p>
                We would like to start this guide with a disappointed tone
                toward ShiftUp. The whole point is that these Favorite Items are
                supposed to make units more usable or at least baseline usable.
                Unfortunately, SU has failed to do that with Milk.
              </p>
              <h5>Can you give a brief summary about her changes?</h5>
              <p>
                Milk has received an okay-ish upgrade to her kit. While from the
                get-go we weren't really expecting much out of her due to her
                low popularity, the changes, in the end, have been
                positive—although she still remains underwhelming.
              </p>
              <p>
                The most noticeable changes were the reduction in her Burst
                Skill CD from 40s to 20s and the added CDR bonus for all allies
                for every 10 Full Charge(s). A low Burst Skill CD is a step in
                the right direction, but what's its use if the rest of her kit
                provides literally nothing?
              </p>
              <p>
                Her intermittent ATK buff makes it hard to synchronize with
                characters' DPS windows, and her 2.83s CDR pales in comparison
                to more performant B1 CDRs like Liter or Rapi:RH. Her HP Potency
                is fine, but it is often significant in PvE. In PvP, however,
                its impact is more debatable.
              </p>
              <h5>Should I get her Favorite Item?</h5>
              <p>
                Absolutely not. She's somewhat garbage. Investing in her can
                only mean those things: she is your waifu, you have questionable
                resource management skills, or you just want A LOT of unneeded
                flexibility in PvP, or you just want her for collection. Her
                Treasure is likely just a filler to prevent players from feeling
                drained after upgrading Helm and Drake, done to fill up the
                yearly quota. Otherwise, you will have no resources left. It's a
                good thing, is it not? Milk fans would disagree.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                For PvE, Milk only requires Phase 1 + 2 because healing is often
                dispensable, but for PvP, she requires Phase 3 for the lifesteal
                + HP potency.
              </p>
              <h5>Skill 1</h5>
              <blockquote>
                <p>
                  ■ Activates every 20 sec. Affects 3 ally unit(s) with the
                  highest ATK.
                </p>
                <p>ATK ▲ 31.83% for 10 sec.</p>
                <p>■ Activates at the start of the battle. Affects self.</p>
                <p>Cooldown of Burst Skill ▼ 20 sec continuously.</p>
              </blockquote>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Phase 1
                </h6>
                <p>
                  This skill unlocks after you have attached an appropriate SR
                  15 Doll + completed all the prerequisites.
                </p>
              </blockquote>
              <p>
                Milk's ATK buff now applies to 3 units simultaneously instead of
                2, making it more viable in 1-1-3 setups with 3 DPS units.
                However, its intermittent uptime makes it difficult to
                synchronize with DPS windows such as Alice's or SBS's Burst
                Skill. Stable damage dealers won't feel this downside much, but
                the overall buff value is still too low to be worth using.
                Perhaps increasing it to more than 40% would be a good start.
              </p>

              <p>
                That said, predictable Burst buffs like this do have an
                advantage when it comes to buffing certain types of damage, as
                long as the timing aligns. This is because their low uptime is
                often compensated by a higher buff value (see Yulha). If you can
                time SW's Burst, for example, to hit when this skill is active,
                the damage dealt is higher than it would have been with a
                permanent 5% ATK buff.
              </p>
              <p>
                In addition, the ATK buffs can be triggered off-burst, so you
                can run a 2-1-2 with Milk being an off-burst unit, but
                unfortunately there are better and more consistent options.
              </p>
              <Alert variant="primary">
                <p>
                  Note that passive skills with cooldown like this do not
                  immediately trigger at the start of battles. They need to go
                  down the whole timer first, making them suboptimal for short
                  battles like most Story and PvP.
                </p>
              </Alert>
              <p>
                This skill also has a second component that reduces her Burst
                Skill cooldown from 40s to 20s, which is an acceptable change,
                knowing that her Burst Skill is weak. This allows her to support
                1-1-3 comps as mentioned earlier, but that doesn't automatically
                make her a better choice than her competitors. The rest of her
                kit will decide whether she will be useful (spoiler: she won't).
              </p>
              <h5>Skill 2</h5>
              <blockquote>
                <p>■ Activates when above 80% HP. Affects all allies.</p>
                <p>Critical Damage ▲ 11.13% continuously.</p>
                <p>
                  ■ Activates when attacking with Full Charge for 10 time(s).
                  Affects all allies.
                </p>
                <p>Cooldown of Burst Skill ▼ 2.83 sec.</p>
              </blockquote>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Phase 2
                </h6>
                <p>
                  This skill unlocks after you have collected 50 matching
                  Favorite Item(s) material.
                </p>
              </blockquote>
              <p>
                Milk's second skill now applies a universal CDR every time she
                lands 10 fully charged slots. Now, this is a good change as she
                now has a purpose as a CDR instead of nothing. However, who
                thinks 10 fully charged shots for just a measly 2.83s CDR at max
                level a good deal? That is one entire rotation. Compared to
                Rapi:RH's ~7.5s reduction, and D:Wife's and Rouge's 7s
                reduction, Milk's CDR is simply nonexistent. Sure, it's a
                welcome change but far from enough...
              </p>
              <Alert variant="primary">
                <p>
                  Like other CDRs that rely on landing a certain number of Fully
                  Charged shots, Milk's CDR can be triggered even if she is in
                  the off-burst position. The only problem is that the value is
                  too low to matter.
                </p>
              </Alert>
              <h5>Skill 3</h5>
              <blockquote>
                <p>■ Affects 1 enemy unit(s) with the highest DEF.</p>
                <p>Deals 367.34% of final ATK as damage.</p>
                <p>Attack Damage ▲ 20.9% for 10 sec.</p>
                <p>■ Affects all allies.</p>
                <p>Recovers 16.16% of attack damage as HP over 10 sec.</p>
                <p>Potency of HP restored ▲ 75.5% for 10 sec.</p>
              </blockquote>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Phase 3
                </h6>
                <p>
                  This skill unlocks after you have collected another 110
                  matching Favorite Item(s) material.
                </p>
              </blockquote>
              <p>
                Milk's Burst Skill now applies a teamwide HP potency, boosting
                teammates' recovery by 75.5% for 10s. This synergizes with her
                lifesteal, making her an ideal candidate for survival-focused B1
                CDR. However, In PvE, survivability is rarely the concern, and
                her lack of offense is a more serious problem. In PvP, though,
                the healing + potency can possibly be a game-changer for stall
                comps. At max level, the healing should be around ~50% of Helm's
                healing solo across 2 FB. That is fairly low, but her HP potency
                is huge, so the ideal play is to pair her with other healers
                such as HelmTr.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="b">B</strong> | Campaign (HD)
                - <strong className="b">B</strong>
              </h5>
              <p>
                Theoretically only worth using if you don't have other CDRs, but
                if you are at High Deficit, own Milk, and have enough resources
                to build her Treasure, you should already have a built Liter.
                Just use that Liter. Period. Oh, and Liter is technically free
                because you can get her from ordinary pity!
              </p>
              <p>
                <i>
                  PS: Milk's healing will not help you survive longer at high
                  deficit where everything oneshots you! So you need offensive
                  buffs to counterbalance the stat penalty and kill enemies
                  faster!
                </i>
              </p>
              <h5>
                Bossing - <strong className="c">C</strong>
              </h5>
              <p>
                Milk's low CDR and irregular buffs (one of them requires her
                being over 80% in HP) make her very clunky. Not only do you have
                to protect her from damage, you also need to bear with CD
                bottlenecks and low damage output. And even if you manage to do
                that, the buff is terrible. It's almost always better to use
                other B1 CDR if available, and if not available, then wait for
                them to be available. Do not invest in Milk just to have a CDR.
                A bad investment will brick your account in the long run.
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                Probably her best battleground at the moment. Lifesteal + HP
                Potency can make for some interesting stall teams (esp. with
                HelmTr). She's too expensive, however. We think that Nero +
                Moran are more performant than her, so if you are planning to
                build a stall team, go with them first before considering Milk.
                By the time you finish building Milk, a new challenger better
                than her would have come out.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                You do not need to OL her (or even level her gear) for Treasure
                because her ATK buff doesn’t scale off the caster's stat, but
                you can overload her if you plan to use her in PvP (for CP
                padding). Save your materials and especially credits.
              </p>
              <p>
                As for the affixes, she doesn't need lines, or rather you
                shouldn't reroll her at all. However, if you insist, she may
                benefit from 1× Max Ammo line to ensure 10 Full-Charge(s) per
                rotation. You shouldn't need any Max Ammo lines in general,
                though, but one is handy. Aside from that, Charge Speed for PvP.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> -
                </li>
                <li>
                  <strong>Passable:</strong> 1× Max Ammo, 3-4× Charge Speed
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Milk shines best in PvP, and the skill that governs her PvP
                performance is Burst Skill. Focus on that.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 3~7</strong>
                </li>
                <ul>
                  <li>
                    Mainly for PvE because it likely won't trigger in PvP due to
                    the long initial CD. -20s CD to Burst Skill does not scale
                    either. A unit with such little presence is not worth
                    maxing. You still want to upgrade for CP padding in PvP.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 3~7</strong>
                </li>
                <ul>
                  <li>
                    If you insist on using her for PvE, then you will need some
                    investments for the CDR, otherwise don't bother—CP padding
                    only. PvP doesn't need such a low CDR for now.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:3~10</strong>
                </li>
                <ul>
                  <li>
                    Mainly for PvP. Upgrade as needed. 10 for the highest
                    lifesteal and HP potency. This is the key to achieving a
                    proper stall team. At max level, it should be around ~50% of
                    Helm's healing solo across 2 FB.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="milk-treasure" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                For PvE, you would want cubes that allow her to Full Charge more
                often. Resilience is your best friend.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/cube_9_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_11_sm.webp"
                alt="Guide"
              />
              <p>
                For PvP, you would want cubes that increase her survivability,
                but Adjutant is also a passable alternative for extra Charge
                Speed if you want to FB faster.
              </p>
              <h5>Doll Investments</h5>
              <p>
                You need SR 15 to Treasure her. No additional information
                needed.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Fire Team With Water</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="milk-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is a mono-Fire team with B1 Water CDR and a B2 CDR,
                probably useful for beginners who have trouble composing a
                suitable team for Water-Weak, Fire Weak, Wind Weak or Iron Weak
                Solo Raids. Rapipi can be substituted in as a superior B3 to any
                one of the 3 fire units. Dolla and S.Helm usually have less
                optimal CDR, but with Milk providing an extra push, they now
                have a competitive CDR rotation for this team. Alternatively,
                use Grave or something like Rapipi as B3 for more offense.
              </p>
              <h5>Team #2: SG Team But With CDR</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="placeholder-box">B2</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="soda-twinkling-bunny"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="milk-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                In this team, we utilize extended Full Burst + MilkTr to speed
                up SG team's rotation a bit. Milk, in the off-burst position,
                can buff the team's DPS periodically. Despite that, the
                acceleration is negligible and would instead cost damage due to
                losing 1 DPS/semi-DPS. However, since SG team is consistently
                outputting DPS, Milk’s ATK buff is very relevant for them. Not
                only that, but she can also provide the option of bursting with
                her once for healing the team if needed. Not the best of teams,
                but definitely a strong idea.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: Heal More, Stall Longer</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="bay" enablePopover />
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                    <NikkeCharacter mode="icon" slug="nero" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="milk-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="quiry" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team uses Milk's lifesteal + HP potency to allow the team
                to survive longer. She synergizes well with B2 and B3 who
                fortify the team. Make sure to use HelmTr's B3 for insane
                lifesteal capabilities. Other stall ideas where more healers
                such as Maid Anchor, Trina are used may also net positive
                results.
              </p>
              <h5>Team #2: Simple 3RL Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="milk-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Lifesteal Sanis is insane, because she hits 2 targets with S2 +
                her auto attack alone making her dishing out tons of paper
                damage. Those damages will heal her back a lot even with just a
                moderate amount of lifesteal. Noted that if she hits Immune
                Nikke, she will heal 0. Noah can be replaced with Bay but then
                you need Emilia instead of Laplace/XMaiden.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>She is now a 20s CDR unit.</li>
                        <li>
                          Only B1 Water CDR aside from Dorothy, albeit not as
                          effective.
                        </li>
                        <li>
                          ATK buffs are automatic and can be triggered
                          off-burst.
                        </li>
                        <li>CDR can be triggered off-burst.</li>
                        <li>Insane HP potency on top of lifesteal.</li>
                        <li>Healer CDR?</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Questionable CDR value and requirement.</li>
                        <li>
                          ATK buffs are irregular and may not coincide with
                          powerful attacks.
                        </li>
                        <li>
                          CRT DMG value is low, and even then, the buff type
                          itself is weak.
                        </li>
                        <li>Burst Damage is low.</li>
                        <li>
                          Expensive. Requires 2 dupes on top of base copy, and
                          SR 15 Favorite Item.
                        </li>
                        <li>
                          Fickle. Presence and impact may change easily from
                          time to time.
                        </li>
                        <li>Worst of the 4 Treasure(s) this season.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMilk;

export const Head: React.FC = () => (
  <Seo
    title="Milk (Treasure) Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Milk (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
